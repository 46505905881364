import { Button, Flex, Text } from '@smkt-web/rampa'
import cn from 'classnames'
import { type FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

import { GoodCard } from 'src/entities/GoodCard/ui/GoodCard'
import { ContactUsModal } from 'src/features/ContactUsModal/ui/ContactUsModal'
import { MakeUpper } from 'src/features/MakeUpper/ui/MakeUpper'
import { Footer, Header } from 'src/shared'
import items from 'src/shared/products.json'

import styles from './ProductPage.scss'

interface Category {
  type: string
  name: string
  imageUrl: string
  products: Product[]
}

interface Product {
  sku: number
  productId: number
  name: string
  imageUrl: string
  swatchImageUrl: string
}

export const ProductPage: FC = () => {
  const { id } = useParams()

  const [disableEffect, setDisableEffect] = useState(false)
  const [sku, setSku] = useState<number>()
  const [currentProduct, setCurrentProduct] = useState<Product>()
  const [anotherProducts, setAnotherProducts] = useState<Product[]>([])
  const [similarProducts, setSimilarProducts] = useState<Product[]>([])
  const [isOpenModal, setOpenModal] = useState<boolean>(false)

  const navigate = useNavigate()

  const handleOpenModal = useCallback(() => {
    setOpenModal(true)
  }, [])

  useEffect(() => {
    if (id) {
      setSku(Number(id))
    }
  }, [id])

  useMemo(() => {
    let current: Product | undefined = undefined
    let currentCategory: Category | undefined = undefined
    for (const category of items.categories) {
      current = category.products.find((item: any) => item.sku === Number(id))
      if (current) {
        currentCategory = category
        setCurrentProduct(current)

        break
      }
    }

    if (current && currentCategory) {
      const { another, similar } = currentCategory.products.reduce<{
        another: Product[]
        similar: Product[]
      }>(
        (acc, item) => {
          if (item.productId === current?.productId) {
            acc.another.push(item)
          } else {
            acc.similar.push(item)
          }

          return acc
        },
        { another: [], similar: [] },
      )

      setAnotherProducts(another)
      setSimilarProducts((prev) => (prev.length ? prev : similar.sort(() => 0.5 - Math.random()).slice(0, 3)))
    }
  }, [id])

  const handleNavigate = useCallback(() => {
    navigate('/')
  }, [navigate])

  const handleChangeSku = (productSku: number) => {
    navigate(`/product/${productSku}`)
  }

  const handleChangeDisableEffect = useCallback(() => {
    setDisableEffect(true)
  }, [])

  return (
    <Flex isFluid flexDirection="column">
      <Header isInner title="Назад" onBack={handleNavigate} />
      <div className={styles.Content}>
        <div className={styles.GridContainer}>
          <MakeUpper disabled={disableEffect} sku={sku} />
          <div className={styles.Right}>
            <Flex flexDirection="column" space={2}>
              <Text type="header-2">{currentProduct?.name}</Text>
              <div className={styles.ListTone}>
                <button
                  className={cn(styles.ToneButtonOff, {
                    [styles.isActive]: disableEffect,
                  })}
                  onClick={handleChangeDisableEffect}
                >
                  <svg fill="none" height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg">
                    <path
                      clipRule="evenodd"
                      d="M12.6464 0.646408L0.646447 12.6464L1.35355 13.3535L3.56327 11.1438C4.59148 11.6908 5.76036 12 6.99995 12C10.2174 12 12.9583 9.91702 14 6.99974C13.4779 5.5378 12.5291 4.28541 11.3074 3.39972L13.3536 1.35352L12.6464 0.646408ZM10.5899 4.11721C11.6148 4.82168 12.4332 5.82036 12.9278 6.99977C11.937 9.3625 9.647 11 6.99995 11C6.04099 11 5.1289 10.7851 4.30845 10.3986L5.26173 9.44534C5.75214 9.79457 6.35208 9.99996 7 9.99996C8.65685 9.99996 10 8.65682 10 6.99996C10 6.35205 9.7946 5.7521 9.44537 5.26169L10.5899 4.11721ZM8.72309 5.98398C8.89903 6.28174 9 6.62906 9 6.99996C9 8.10453 8.10457 8.99996 7 8.99996C6.6291 8.99996 6.28177 8.899 5.98402 8.72305L8.72309 5.98398ZM8.75291 3.24705L9.54471 2.45525C8.75073 2.16059 7.89379 1.99987 7.00008 1.99987C3.78266 1.99987 1.0417 4.08281 0 7.00009C0.416085 8.16516 1.1032 9.19715 1.98345 10.0165L2.69091 9.30905C2.00106 8.67426 1.4448 7.88843 1.07221 7.00006C2.06304 4.63733 4.35303 2.99987 7.00008 2.99987C7.6069 2.99987 8.19497 3.08593 8.75291 3.24705ZM7 4.99996L7.87146 4.1285C7.59568 4.04491 7.30309 3.99996 7 3.99996C5.34315 3.99996 4 5.34311 4 6.99996C4 7.30305 4.04495 7.59564 4.12854 7.87143L5 6.99996C5 5.89539 5.89543 4.99996 7 4.99996Z"
                      fill="#6E7072"
                      fillRule="evenodd"
                    />
                  </svg>
                </button>
                {anotherProducts.map((item) => (
                  <button
                    className={cn(styles.ToneButton, {
                      [styles.isActive]: !disableEffect && item.sku === currentProduct?.sku,
                    })}
                    key={item.sku}
                    onClick={() => {
                      setDisableEffect(false)
                      handleChangeSku(item.sku)
                    }}
                  >
                    <span className={styles.ToneButtonCircle}>
                      <img className={styles.ToneButtonImg} loading="lazy" src={item.swatchImageUrl} />
                    </span>
                  </button>
                ))}
              </div>
              <div>
                <Button onClick={handleOpenModal}>Пишите нам</Button>
              </div>
            </Flex>

            <div>
              <Text className={styles.RandomListTitle} type="header-2">
                Слични производи
              </Text>
              <div className={styles.RandomList}>
                {similarProducts.map((item) => (
                  <div className={styles.RandomListItem} key={item.sku}>
                    <GoodCard imageUrl={item.imageUrl} name={item.name} sku={item.sku} type="small" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ContactUsModal isOpen={isOpenModal} setIsOpen={setOpenModal} />
    </Flex>
  )
}
