const SCRIPT_ID = 'makeupar'
const promise = new Promise((resolve) => {
  window.ymkAsyncInit = () => {
    resolve(undefined)
  }
})

export const makeUpperSetup = () => {
  if (!document.getElementById(SCRIPT_ID)) {
    const script = document.createElement('script')

    script.id = SCRIPT_ID
    script.src = 'https://plugins-media.makeupar.com/c46999/sdk.js?apiKey=jXE4pSCk5suVXufDLTDLHA'
    script.async = true

    document.body.appendChild(script)
  }

  return promise
}
