import { Flex } from '@smkt-web/rampa'
import { type FC, useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { GoodCard } from 'src/entities/GoodCard/ui/GoodCard'
import { Footer, Header } from 'src/shared'
import items from 'src/shared/products.json'

import styles from './CategoryPage.scss'

export const CategoryPage: FC = () => {
  const [products, setProducts] = useState<any[]>([])

  const navigate = useNavigate()
  const { id } = useParams()

  useEffect(() => {
    const currentProduct = items.categories.filter((item) => item.type === id)[0]
    setProducts(currentProduct.products)
  }, [id])

  const handleNavigate = useCallback(() => {
    navigate(-1)
  }, [navigate])

  return (
    <Flex isFluid flexDirection="column">
      <Header isInner title="Назад" onBack={handleNavigate} />
      <div className={styles.Content}>
        <div className={styles.List}>
          {products.map(({ imageUrl, sku, name }: { imageUrl: string; sku: number; name: string }) => (
            <GoodCard imageUrl={imageUrl} key={sku} name={name} sku={sku} />
          ))}
        </div>
      </div>
      <Footer />
    </Flex>
  )
}
