import { type FC, useCallback } from 'react'
import { useNavigate } from 'react-router'

import styles from './GoodCard.scss'

interface GoodCardPropsType {
  sku: number
  name: string
  imageUrl: string
  type?: 'small' | 'medium'
}

export const GoodCard: FC<GoodCardPropsType> = ({ sku, name, imageUrl, type = 'medium' }) => {
  const navigate = useNavigate()
  const handleClick = useCallback(() => navigate(`/product/${sku}`), [sku, navigate])
  return (
    <div className={styles.GoodCard} onClick={handleClick}>
      <div className={styles.GoodCardContent}>
        <img
          className={type === 'medium' ? styles.GoodCardImageMedium : styles.GoodCardImageSmall}
          loading="lazy"
          src={imageUrl}
        />
        <p>{name}</p>
      </div>
    </div>
  )
}
