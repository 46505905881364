import { Text } from '@smkt-web/rampa'
import type { FC } from 'react'

import styles from './Footer.scss'

export const Footer: FC = () => (
  <footer className={styles.Footer}>
    <Text align="center" color="inverse" type="body-2">
      Copyright &copy;&nbsp;2024 Козметика коју можете да&nbsp;испробате онлајн
    </Text>
  </footer>
)
