import { Button, CloseButton, Form, Loader, Notification, TextInputField, TextareaField } from '@smkt-web/rampa'
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react'
import { Resolver, useForm } from 'react-hook-form'

import styles from './ContactUsModal.scss'

interface ContactUsModalPropsType {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

export const ContactUsModal = ({ isOpen, setIsOpen }: ContactUsModalPropsType) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false)
  const onSubmit = handleSubmit(() => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
      setIsNotificationOpen(true)
      setIsOpen(false)
    }, 1000)
  })

  useEffect(() => {
    window.document.body.style.overflow = isOpen ? 'hidden' : 'auto'

    if (isNotificationOpen) {
      setTimeout(() => {
        setIsNotificationOpen(false)
      }, 1000)
    }
  }, [isOpen, isNotificationOpen])

  return (
    <>
      {isOpen && (
        <div className={styles.Modal}>
          {isLoading && <Loader isOverlay size="medium" />}
          <div className={styles.ModalWrapper}>
            <Form className={styles.FormWrapper} onSubmit={onSubmit}>
              <div className={styles.ModalHeader}>
                <p>Пишите нам</p>
                <CloseButton
                  onClick={() => {
                    setIsOpen(false)
                  }}
                />
              </div>
              <div className={styles.ModalContent}>
                <TextInputField
                  className={styles.ModalField}
                  id="Ime"
                  label="Име"
                  {...register('firstName', { required: true })}
                  error={errors?.firstName && 'Обавезно'}
                />
                <TextInputField
                  className={styles.ModalField}
                  id="Prezime"
                  label="Презиме"
                  {...register('lastName', { required: true })}
                  error={errors?.lastName && 'Обавезно'}
                />
                <TextInputField
                  className={styles.ModalField}
                  id="E-mail"
                  label="E-mail"
                  {...register('email', { required: true })}
                  error={errors?.email && 'Обавезно'}
                />
                <TextInputField
                  className={styles.ModalField}
                  id="Telefon"
                  label="Телефон"
                  {...register('phone', { required: true })}
                  error={errors?.phone && 'Обавезно'}
                />
                <TextareaField
                  id="Komentar"
                  label="Коментар"
                  {...register('comment', { required: true })}
                  error={errors?.comment && 'Обавезно'}
                />
              </div>
              <div className={styles.ModalFooter}>
                <Button
                  className={styles.ModalFooterButton}
                  kind="secondary"
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  Прекидање
                </Button>
                <Button className={styles.ModalFooterButton} onClick={() => onSubmit()}>
                  Пошаљи
                </Button>
              </div>
            </Form>
          </div>
        </div>
      )}
      {isNotificationOpen && (
        <Notification
          className={styles.Notification}
          title="Хвала вам!"
          type="success"
          onClose={() => setIsNotificationOpen(false)}
        >
          Добили смо ваше податке
        </Notification>
      )}
    </>
  )
}
