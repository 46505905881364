import { Button, Flex, Text } from '@smkt-web/rampa'
import { type FC, useCallback, useState } from 'react'

import { ContactUsModal } from 'src/features/ContactUsModal/ui/ContactUsModal'
import { Footer, Header } from 'src/shared'
import items from 'src/shared/products.json'

import { CategoryCard } from '../../../entities/CategoryCard/ui/CategoryCard'
import { GoodCard } from '../../../entities/GoodCard/ui/GoodCard'

import styles from './MainPage.scss'

export const MainPage: FC = () => {
  const [isModalOpened, setModalOpened] = useState<boolean>(false)
  const handleOpenForm = useCallback(() => setModalOpened(true), [])

  return (
    <Flex isFluid flexDirection="column">
      <Header title="Козметика коју можете да испробате онлајн" />
      <div className={styles.Content}>
        <Flex alignItems="center" className={styles.MainBlock} justifyContent="space-between" space={6}>
          <div>
            <Text className={styles.Title} type="header-3">
              Имамо већи избор козметике, коју можете да&nbsp;испробате онлајин онда и&nbsp;наставите са&nbsp;куповином
              производа.
            </Text>
            <Text className={styles.Description} type="body-2">
              Овде можете да&nbsp;нађете већи асортиман козметике за&nbsp;сваку прилику. Пробајте нашу виртујељниј
              кабину за&nbsp;процену изгледа шминке, даби сте могли да&nbsp;видите уживо резултат на&nbsp;екрану. Ово је
              брз и&nbsp;ефикасан погодак да&nbsp;бих сте нашли идеалну шминку без излазака од&nbsp;куће
            </Text>
            <Button onClick={handleOpenForm}>Пишите нам</Button>
          </div>
          <div>
            <div className={styles.Picture} />
          </div>
        </Flex>
        <div className={styles.Categories}>
          <Text className={styles.CategoriesTitle} type="header-2">
            Категорије
          </Text>
          <div className={styles.CategoriesList}>
            {items.categories.map(({ type, name, imageUrl }: { type: string; name: string; imageUrl: string }) => (
              <CategoryCard imageUrl={imageUrl} key={type} name={name} type={type} />
            ))}
          </div>
        </div>
        <div>
          <Text className={styles.ProductsTitle} type="header-2">
            Популарни производи
          </Text>
          <div className={styles.ProductList}>
            {items.popular.map(({ sku, name, imageUrl }: { sku: number; name: string; imageUrl: string }) => (
              <GoodCard imageUrl={imageUrl} key={sku} name={name} sku={sku} />
            ))}
          </div>
        </div>
      </div>
      <Footer />
      <ContactUsModal isOpen={isModalOpened} setIsOpen={setModalOpened} />
    </Flex>
  )
}
