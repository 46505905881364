import { type FC, useCallback, useState } from 'react'
import { Route, Routes } from 'react-router-dom'

import { CategoryPage, MainPage, ProductPage } from 'src/pages'

export const App: FC = () => {
  return (
    <Routes>
      <Route element={<MainPage />} path="/" />
      <Route element={<CategoryPage />} path="/category/:id" />
      <Route element={<ProductPage />} path="/product/:id" />
    </Routes>
  )
}
