import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.scss'
import { HashRouter } from 'react-router-dom'

import { App } from './app/ui/App'

const root = createRoot(document.getElementById('root')!)
root.render(
  <React.StrictMode>
    <HashRouter>
      <App />
    </HashRouter>
  </React.StrictMode>,
)
