import { Loader } from '@smkt-web/rampa'
import { type FC, useEffect, useState } from 'react'

import { useGetCameraAiSize } from 'src/features/MakeUpper/model/getCameraAiSize'
import { makeUpperSetup } from 'src/features/MakeUpper/model/makeUpperSetup'

import styles from './MakeUpper.scss'

interface MakeUpperPropsType {
  sku?: number
  disabled: boolean
}

export const MakeUpper: FC<MakeUpperPropsType> = ({ sku, disabled }) => {
  const [isLoading, setLoading] = useState<boolean>(true)

  const width = useGetCameraAiSize()

  useEffect(() => {
    let destroyed = false

    makeUpperSetup().then(() => {
      if (destroyed) {
        return
      }

      setLoading(true)

      window.YMK.addEventListener('uiLoaded', () => {
        setLoading(false)
      })

      window.YMK.init({
        hideButtonsOnResultPage: true,
        disableResolutionCheck: true,
        optOutGoogleAnalytics: true,
        userGoogleAnalyticsConsent: 'disagree',
        width: width,
        height: 584,
      })

      window.YMK.open([true])
    })

    return () => {
      destroyed = true
      window.YMK?.closeEngine()
    }
  }, [width])

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (sku && !disabled) {
      window.YMK.applyMakeupBySku(sku, (success: boolean) => {
        if (!success) {
          console.error('applyMakeupBySku failed')
        }
      })
    }

    return () => {
      window.YMK.reset()
    }
  }, [disabled, isLoading, sku])

  return (
    <div className={styles.Container}>
      {isLoading && <Loader className={styles.Loader} />}
      <div id="YMK-module" />
    </div>
  )
}
