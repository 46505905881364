import { Button, Text } from '@smkt-web/rampa'
import type { FC } from 'react'

import styles from './Header.scss'

interface HeaderPropsType {
  title: string
  isInner?: boolean
  onBack?: VoidFunction
}
export const Header: FC<HeaderPropsType> = ({ title, isInner, onBack }) => {
  return (
    <div className={styles.Header}>
      {isInner && onBack && <Button icon="arrowLeft" kind="secondary" onClick={onBack} />}
      <Text className={styles.Title} type="header-2">
        {title}
      </Text>
    </div>
  )
}
