import { useMemo } from 'react'

const DEFAULT_WIDTH = 447

export const useGetCameraAiSize = () => {
  const width = window.innerWidth
  return useMemo(() => {
    if (width < 1024) {
      return width
    }

    return DEFAULT_WIDTH
  }, [width])
}
