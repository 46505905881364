import { Flex, Icon } from '@smkt-web/rampa'
import { type FC, useCallback } from 'react'
import { useNavigate } from 'react-router'

import styles from './CategoryCard.scss'

interface CategoryCardPropsType {
  type: string
  name: string
  imageUrl: string
}

export const CategoryCard: FC<CategoryCardPropsType> = ({ type, name, imageUrl }) => {
  const navigate = useNavigate()
  const handleClick = useCallback(() => navigate(`/category/${type}`), [type, navigate])

  return (
    <div className={styles.CategoryCard} onClick={handleClick}>
      <div className={styles.CategoryCardContent}>
        <img className={styles.CategoryCardImage} loading="lazy" src={imageUrl} />
        <Flex alignItems="center" space={2}>
          {name}
          <Icon name="arrowRight" />
        </Flex>
      </div>
    </div>
  )
}
